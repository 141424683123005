import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'ngx-download-apps-modal',
  templateUrl: './download-apps-modal.component.html',
  styleUrls: ['./download-apps-modal.component.scss']
})
export class DownloadAppsModalComponent implements OnInit {

  constructor() { }

  linux : string;
  mac : string;
  windows : string;
  appVersion : string;
  appStoreUrl : string;
  playStoreUrl : string;
  ngOnInit(): void {
    this.linux = environment.linuxDownloadUrl;
    this.mac = environment.macDownloadUrl;
    this.windows = environment.windowsDownloadUrl;
    this.appVersion = environment.appVersion;
    this.appStoreUrl = environment.macDownloadApplicationURL;
    this.playStoreUrl = environment.androidDownloadApplicationURL;
  }

}
